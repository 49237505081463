import Vue from "vue";
import VueRouter from "vue-router";
import store from './../store'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import StudentLogin from '../views/StudentLogin.vue'
import StudentDashoard from '../views/StudentDashboard.vue'

Vue.use(VueRouter);

async function guard(to, from, next){
    let token = localStorage.getItem('jwtToken');
    let userData = localStorage.getItem('userData');
    await axios.get(`${router.app.$store.state.host}/verification-token`, {
        headers: {
        	Authorization: token ? token : null
        }
    })
    .then(res => {
		if(!res.data) {
			localStorage.removeItem('jwtToken')
            token = null;
		}
	})
    .catch(e => {
        if(e.response.statusText == 'Unauthorized'){
            localStorage.removeItem('jwtToken')
            token = null;
        }
    })

    if(token == null || token == undefined || token == '' || userData.userType == 's') {
        next({ name: 'Login' })
    } 
    else next()
}

const routes = [
	{
		path: '/login',
		name: 'Login',
		meta: { color: 'brown', layout: 'simpleLayout' },
		component: Login
	},
	{
		path: '/studentportal',
		name: 'StudentLogin',
		meta: { color: 'brown', layout: 'simpleLayout' },
		component: StudentLogin
	},
	{
		path: '/student-panel',
		name: 'StudentDashboard',
		meta: { color: 'brown', layout: 'studentLayout'},
		component: StudentDashoard
	},
	{
		path: '/student-result',
		name: 'StudentResutl',
		meta: { color: 'brown', layout: 'studentLayout'},
		component: () => import(/* webpackChunkName: "student-result" */ '../views/Student/StudentResutl.vue')
	},
	{
		path: '/student-attendance',
		name: 'StudentAttendance',
		meta: { color: 'brown', layout: 'studentLayout'},
		component: () => import(/* webpackChunkName: "student-attendance" */ '../views/Student/StudentAttendance.vue')
	},
	{
		path: '/payment-report',
		name: 'StudentPayment',
		meta: { color: 'brown', layout: 'studentLayout'},
		component: () => import(/* webpackChunkName: "payment-report" */ '../views/Student/StudentPayment.vue')
	},
	{
		path: '/studentdue',
		name: 'StudentPortalDue',
		meta: { color: 'brown', layout: 'studentLayout'},
		component: () => import(/* webpackChunkName: "studentdue" */ '../views/Student/StudentPortalDue.vue')
	},
	{
		path: '/student-classtest',
		name: 'StudentClassTest',
		meta: { color: 'brown', layout: 'studentLayout'},
		component: () => import(/* webpackChunkName: "student-classtest" */ '../views/Student/ClassTest.vue')
	},
	{
		path: '/duepayment',
		name: 'StudentDuePayment',
		meta: { color: 'brown', layout: 'studentLayout'},
		component: () => import(/* webpackChunkName: "duepayment" */ '../views/Student/DuePayment.vue')
	},
	{
		path: '/',
		beforeEnter: guard,
		name: 'Dashboard',
		meta: { color: 'brown' },
		component: Dashboard
	},
	{
		path: '/user',
		beforeEnter: guard,
		name: 'User',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "user" */ '../views/Administration/User.vue')
	},
	{
		path: '/user-profile-setting',
		beforeEnter: guard,
		name: 'ProfileSetting',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "user-profile-setting" */ '../views/Administration/ProfileSetting.vue')
	},
	{
		path: '/classes',
		beforeEnter: guard,
		name: 'Classes',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "classes" */ '../views/Administration/Classes.vue')
	},
	{
		path: '/building',
		beforeEnter: guard,
		name: 'Building',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "building" */ '../views/Administration/Building.vue')
	},
	{
		path: '/locations',
		beforeEnter: guard,
		name: 'Locations',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "locations" */ '../views/Administration/Locations.vue')
	},
	{
		path: '/subjects',
		beforeEnter: guard,
		name: 'Subjects',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "subjects" */ '../views/Administration/Subjects.vue')
	},
	{
		path: '/sections',
		beforeEnter: guard,
		name: 'Sections',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "sections" */ '../views/Administration/Sections.vue')
	},
	{
		path: '/sessions',
		beforeEnter: guard,
		name: 'Sessions',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "sessions" */ '../views/Administration/Sessions.vue')
	},
	{
		path: '/departments',
		beforeEnter: guard,
		name: 'Departments',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "departments" */ '../views/Administration/Departments.vue')
	},
	{
		path: '/designations',
		beforeEnter: guard,
		name: 'Designations',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "designations" */ '../views/Administration/Designations.vue')
	},
	{
		path: '/branches',
		beforeEnter: guard,
		name: 'Branch',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "branches" */ '../views/Administration/Branch.vue')
	},
	{
		path: '/basic-setting',
		beforeEnter: guard,
		name: 'BasicSetting',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "basic-setting" */ '../views/Administration/BasicSetting.vue')
	},
	{
		path: '/years',
		beforeEnter: guard,
		name: 'Years',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "years" */ '../views/Administration/Years.vue')
	},
	{
		path: '/months',
		beforeEnter: guard,
		name: 'Months',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "months" */ '../views/Administration/Months.vue')
	},
	{
		path: '/fees-head',
		beforeEnter: guard,
		name: 'FeesAccount',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "fess-head" */ '../views/Administration/FessAccount.vue')
	},
	{
		path: '/fees-setting',
		beforeEnter: guard,
		name: 'FeesSetting',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "fess-setting" */ '../views/Administration/FessSetting.vue')
	},
	{
		path: '/areas',
		beforeEnter: guard,
		name: 'Areas',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "areas" */ '../views/Administration/Area.vue')
	},
	{
		path: '/countries',
		beforeEnter: guard,
		name: 'Countries',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "countries" */ '../views/Administration/Country.vue')
	},
	{
		path: '/business-monitor',
		beforeEnter: guard,
		name: 'BusinessMonitor',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "business" */ '../views/Administration/BusinessMonitor.vue')
	},
	/* student moduel start*/
	{
		path: '/student/:id?',
		beforeEnter: guard,
		name: 'StudentEntry',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "student" */ '../views/Student/Student.vue')
	},
	{
		path: '/students',
		beforeEnter: guard,
		name: 'Students',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "students" */ '../views/Student/Students.vue')
	},
	{
		path: '/attendance',
		beforeEnter: guard,
		name: 'Attendance',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "attendance" */ '../views/Student/Attendance.vue')
	},
	{
		path: '/student-id-card',
		beforeEnter: guard,
		name: 'StudentIdCard',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "student-id-card" */ '../views/Student/StudentIdCard.vue')
	},
	{
		path: '/attendance-record',
		beforeEnter: guard,
		name: 'AttendanceRecord',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "attendance" */ '../views/Student/AttendanceRecord.vue')
	},
	{
		path: '/student-record',
		beforeEnter: guard,
		name: 'StudentRecord',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "student-record" */ '../views/Student/StudentRecord.vue')
	},
	{
		path: '/delete-student-record',
		beforeEnter: guard,
		name: 'DeleteStudentRecord',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "delete-student-record" */ '../views/Student/DeleteStudentRecord.vue')
	},
	{
		path: '/Gurdians',
		beforeEnter: guard,
		name: 'Gurdians',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "gurdians" */ '../views/Student/Gurdians.vue')
	},
	{
		path: '/Parents',
		beforeEnter: guard,
		name: 'Parents',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "parents" */ '../views/Student/Parents.vue')
	},
	{
		path: '/collection-setting',
		beforeEnter: guard,
		name: 'CollectionSetting',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "collection-setting" */ '../views/Student/CollectionSetting.vue')
	},
	{
		path: '/fees-collection',
		beforeEnter: guard,
		name: 'FeesCollection',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "fees-collection" */ '../views/Student/FeesCollection.vue')
	},
	{
		path: '/collection-invoice/:id',
		beforeEnter: guard,
		name: 'CollectionInvoce',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "collection-invoice" */ '../views/Student/CollectionInvoice.vue')
	},
	{
		path: '/colleciton-report',
		beforeEnter: guard,
		name: 'CollectionReport',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "colleciton-report" */ '../views/Student/CollectionReport.vue')
	},
	{
		path: '/student-dues',
		beforeEnter: guard,
		name: 'StudentDue',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "student-dues" */ '../views/Student/StudentDue.vue')
	},
	{
		path: '/monthly-dues',
		beforeEnter: guard,
		name: 'MonthlyDue',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "monthly-dues" */ '../views/Student/MonthlyDue.vue')
	},
	{
		path: '/collection-dues',
		beforeEnter: guard,
		name: 'CollectionDue',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "collection-dues" */ '../views/Student/CollectionDue.vue')
	},
	{
		path: '/promotion',
		beforeEnter: guard,
		name: 'PromotionEntry',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "promotion" */ '../views/Student/Promotion.vue')
	},
	{
		path: '/promotion-report',
		beforeEnter: guard,
		name: 'PromotionReport',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "promotion-report" */ '../views/Student/PromotionReport.vue')
	},
	{
		path: '/tc-entry',
		beforeEnter: guard,
		name: 'TCEntry',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "tc-entry" */ '../views/Student/TCEntry.vue')
	},
	{
		path: '/tc-record',
		beforeEnter: guard,
		name: 'TCRecord',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "tc-entry" */ '../views/Student/TCRecord.vue')
	},
	{
		path: '/tc-invoice/:id',
		beforeEnter: guard,
		name: 'TCInvoice',
		meta: { color: 'light-green' },
		component: () => import(/* webpackChunkName: "tc-invoice" */ '../views/Student/TCInvoice.vue')
	},
	/* student moduel end*/

	/* Employee module start*/
	{
		path: '/employee/:id?',
		beforeEnter: guard,
		name: 'Employee',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "employee" */ '../views/Employee/Employee.vue')
	},
	{
		path: '/employees',
		beforeEnter: guard,
		name: 'EmployeeList',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "employees" */ '../views/Employee/EmployeeList.vue')
	},
	{
		path: '/employee-record',
		beforeEnter: guard,
		name: 'EmployeeRecord',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "employee-record" */ '../views/Employee/EmployeeRecord.vue')
	},
	{
		path: '/ex-employee-list',
		beforeEnter: guard,
		name: 'ExEmployeeList',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "ex-employee-list" */ '../views/Employee/ExEmployeeList.vue')
	},
	{
		path: '/employee-type',
		beforeEnter: guard,
		name: 'EmployeeType',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "employee-type" */ '../views/Employee/EmployeeType.vue')
	},
	{
		path: '/employee-attendance',
		beforeEnter: guard,
		name: 'EmployeeAttendance',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "employee-attendance" */ '../views/Employee/Attendance.vue')
	},
	{
		path: '/employee-attendances',
		beforeEnter: guard,
		name: 'EmployeeAttendanceRecord',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "employee-attendances" */ '../views/Employee/AttendanceRecord.vue')
	},
	{
		path: '/salary-generate/:id?',
		beforeEnter: guard,
		name: 'SalaryGenerate',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "salary-generate" */ '../views/Employee/SalaryGenerate.vue')
	},
	{
		path: '/salary-generate-invoice/:id',
		beforeEnter: guard,
		name: 'SalaryGenerateInvoice',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "salary-generate-invoice" */ '../views/Employee/SalaryGenerateInvoice.vue')
	},
	{
		path: '/salary-statement',
		beforeEnter: guard,
		name: 'SalaryStatement',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "salary-statement" */ '../views/Employee/SalaryStatement.vue')
	},
	{
		path: '/salary-generate-record',
		beforeEnter: guard,
		name: 'SalaryGenerateRecord',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "salary-generate-record" */ '../views/Employee/SalaryGenerateRecord.vue')
	},
	{
		path: '/employee-payment',
		beforeEnter: guard,
		name: 'EmployeePayment',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "employee-payment" */ '../views/Employee/EmployeePayment.vue')
	},
	{
		path: '/employee-payment-invoice/:id',
		beforeEnter: guard,
		name: 'EmployeePaymentInvoice',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "employee-payment-invoice" */ '../views/Employee/EmployeePaymentInvoice.vue')
	},
	{
		path: '/pf-transaction',
		beforeEnter: guard,
		name: 'PFTransaction',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "pf-transaction" */ '../views/PF/PFTransaction.vue')
	},
	{
		path: '/pf-transaction-invoice/:id',
		beforeEnter: guard,
		name: 'PFTransactionInvoice',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "pf-transaction-invoice" */ '../views/PF/PFTransactionInvoice.vue')
	},
	{
		path: '/pf-transactions',
		beforeEnter: guard,
		name: 'PFTransactions',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "pf-transactions" */ '../views/PF/PFTransactions.vue')
	},
	{
		path: '/pf-balance',
		beforeEnter: guard,
		name: 'PFBalance',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "pf-balance" */ '../views/PF/PFBalance.vue')
	},
	{
		path: '/pf-ledger',
		beforeEnter: guard,
		name: 'PFLedger',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "pf-ledger" */ '../views/PF/PFLedger.vue')
	},
	{
		path: '/employee-due',
		beforeEnter: guard,
		name: 'EmployeeDue',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "employee-due" */ '../views/Employee/EmployeeDue.vue')
	},
	{
		path: '/increment',
		beforeEnter: guard,
		name: 'SalaryIncrement',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "increment" */ '../views/Employee/Increment.vue')
	},
	{
		path: '/increment-record',
		beforeEnter: guard,
		name: 'IncrementRecord',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "increment-record" */ '../views/Employee/IncrementRecord.vue')
	},
	{
		path: '/leave-setting',
		beforeEnter: guard,
		name: 'LeaveSetting',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "leave-setting" */ '../views/Employee/Leavesetting.vue')
	},
	{
		path: '/leave',
		beforeEnter: guard,
		name: 'LeaveEntry',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "leave" */ '../views/Employee/Leave.vue')
	},
	{
		path: '/leaves',
		beforeEnter: guard,
		name: 'LeaveRecord',
		meta: { color: 'lime darken-2' },
		component: () => import(/* webpackChunkName: "leaves" */ '../views/Employee/Leaves.vue')
	},
	/* Employee module end*/

	/* Account module  start*/
	{
		path: '/accounts',
		beforeEnter: guard,
		name: 'ExpenseAccount',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "accounts" */ '../views/Account/ExpenseAccount.vue')
	},
	{
		path: '/cash-transaction',
		beforeEnter: guard,
		name: 'CashTransaction',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "cash-transaction" */ '../views/Account/CashTransaction.vue')
	},
	{
		path: '/cash-transactions',
		beforeEnter: guard,
		name: 'TransactionRecord',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "cash-transactions" */ '../views/Account/CashTransactions.vue')
	},
	{
		path: '/cash-transaction-invoice/:id',
		beforeEnter: guard,
		name: 'CashTransactionInvoice',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "cash-transaction-invoice" */ '../views/Account/CashTransactionInvoice.vue')
	},
	{
		path: '/income-expense-report',
		beforeEnter: guard,
		name: 'IncomeExpenseReport',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "income-expense-report" */ '../views/Account/IncomeExpenseReport.vue')
	},
	{
		path: '/colleciton-expense',
		beforeEnter: guard,
		name: 'HeadwiseIncomeExpense',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "colleciton-expense" */ '../views/Account/CollectionExpense.vue')
	},
	{
		path: '/bank-account',
		beforeEnter: guard,
		name: 'BankAccount',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "bank-account" */ '../views/Account/Bank.vue')
	},
	{
		path: '/bank-transaction',
		beforeEnter: guard,
		name: 'BankTransation',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "bank-transaction" */ '../views/Account/BankTransaction.vue')
	},
	{
		path: '/cash-statement',
		beforeEnter: guard,
		name: 'CashStatement',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "cash-statement" */ '../views/Account/CashStatement.vue')
	},
	{
		path: '/cash-view',
		beforeEnter: guard,
		name: 'CashView',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "cash-view" */ '../views/Account/CashView.vue')
	},
	{
		path: '/bank-transactions',
		beforeEnter: guard,
		name: 'BankTransactions',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "bank-transactions" */ '../views/Account/BankTransactions.vue')
	},
	{
		path: '/supplier-payments',
		beforeEnter: guard,
		name: 'SupplierPayments',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "supplier-payments" */ '../views/Supplier/SupplierPayments.vue')
	},
	{
		path: '/employee-payments',
		beforeEnter: guard,
		name: 'EmployeePayments',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "employee-payments" */ '../views/Employee/EmployeePayments.vue')
	},
	{
		path: '/bonus/:id?',
		beforeEnter: guard,
		name: 'Bonus',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "bonus" */ '../views/Account/Bonus.vue')
	},
	{
		path: '/bonus-record',
		beforeEnter: guard,
		name: 'BonusRecord',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "bonus-record" */ '../views/Account/BonusRecord.vue')
	},
	{
		path: '/bonus-invoice/:id',
		beforeEnter: guard,
		name: 'BonusInvoice',
		meta: { color: 'pink' },
		component: () => import(/* webpackChunkName: "bonus-invoice" */ '../views/Account/BonusInvoice.vue')
	},
	/* Account module  end*/

	/* Hostel module  Start*/
	{
		path: '/hostel-monitor',
		beforeEnter: guard,
		name: 'HostelMonitor',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-monitor" */ '../views/Hostel/HostelMonitor.vue')
	},
	{
		path: '/hostel-entry',
		beforeEnter: guard,
		name: 'HostelEntry',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-entry" */ '../views/Hostel/HostelEntry.vue')
	},
	{
		path: '/room-entry',
		beforeEnter: guard,
		name: 'RoomEntry',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "room-entry" */ '../views/Hostel/RoomEntry.vue')
	},
	{
		path: '/seat-entry',
		beforeEnter: guard,
		name: 'SeatEntry',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "seat-entry" */ '../views/Hostel/SeatEntry.vue')
	},
	{
		path: '/hostel-admission',
		beforeEnter: guard,
		name: 'HostelAdmission',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-admission" */ '../views/Hostel/HostelAdmission.vue')
	},
	{
		path: '/hostel-admission-record',
		beforeEnter: guard,
		name: 'HostelAdmissionRecord',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-admission-record" */ '../views/Hostel/HostelAdmissionRecord.vue')
	},
	{
		path: '/hostel-room-record',
		beforeEnter: guard,
		name: 'RoomRecord',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-room-record" */ '../views/Hostel/RoomRecord.vue')
	},
	{
		path: '/hostel-seat-record',
		beforeEnter: guard,
		name: 'SeatRecord',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-seat-record" */ '../views/Hostel/SeatRecord.vue')
	},
	{
		path: '/hostel-cash-transaction',
		beforeEnter: guard,
		name: 'HostelCashTransaction',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-cash-transaction" */ '../views/Hostel/HostelCashTransaction.vue')
	},
	{
		path: '/hostel-cash-transaction-record',
		beforeEnter: guard,
		name: 'HostelCashTransactionRecord',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "hostel-cash-transaction-record" */ '../views/Hostel/HostelCashTransactionRecord.vue')
	},
	/* Hostel module  End*/

	/* library module  Start*/
	{
		path: '/library-monitor',
		beforeEnter: guard,
		name: 'LibraryMonitor',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "library-monitor" */ '../views/Library/LibraryMonitor.vue')
	},
	{
		path: '/member-entry',
		beforeEnter: guard,
		name: 'MemberEntry',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "member-entry" */ '../views/Library/MemberEntry.vue')
	},
	{
		path: '/book-entry',
		beforeEnter: guard,
		name: 'BookEntry',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "book-entry" */ '../views/Library/BookEntry.vue')
	},
	{
		path: '/book-management',
		beforeEnter: guard,
		name: 'BookManagement',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "book-management" */ '../views/Library/BookManagement.vue')
	},
	{
		path: '/book-issue',
		beforeEnter: guard,
		name: 'BookIssue',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "book-issue" */ '../views/Library/BookIssue.vue')
	},
	{
		path: '/book-shelf',
		beforeEnter: guard,
		name: 'BookShelf',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "book-shelf" */ '../views/Library/BookShelf.vue')
	},
	{
		path: '/library-subject',
		beforeEnter: guard,
		name: 'LibrarySubject',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "library-subject" */ '../views/Library/LibrarySubject.vue')
	},
	{
		path: '/library-member-record',
		beforeEnter: guard,
		name: 'LibraryMemberRecord',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "library-member-record" */ '../views/Library/MemberRecord.vue')
	},
	{
		path: '/book-record',
		beforeEnter: guard,
		name: 'BookRecord',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "book-record" */ '../views/Library/BookRecord.vue')
	},
	{
		path: '/book-issue-record',
		beforeEnter: guard,
		name: 'BookIssueRecord',
		meta: { color: 'teal' },
		component: () => import(/* webpackChunkName: "book-issue-record" */ '../views/Library/BookIssueRecord.vue')
	},
	/* library module  end*/

	/* Inventory module start*/
	{
		path: '/units',
		beforeEnter: guard,
		name: 'Units',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "units" */ '../views/Product/Units.vue')
	},
	{
		path: '/categories',
		beforeEnter: guard,
		name: 'Categories',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "categories" */ '../views/Product/Categories.vue')
	},
	{
		path: '/product',
		beforeEnter: guard,
		name: 'Product',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "product" */ '../views/Product/Product.vue')
	},
	{
		path: '/product-ledger',
		beforeEnter: guard,
		name: 'ProductLedger',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "product-ledger" */ '../views/Product/ProductLedger.vue')
	},
	{
		path: '/supplier',
		beforeEnter: guard,
		name: 'Supplier',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "supplier" */ '../views/Supplier/Supplier.vue')
	},
	{
		path: '/supplier-payment',
		beforeEnter: guard,
		name: 'SupplierPayment',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "supplier-payment" */ '../views/Supplier/SupplierPayment.vue')
	},
	{
		path: '/supplier-due',
		beforeEnter: guard,
		name: 'SupplierDue',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "supplier-due" */ '../views/Supplier/SupplierDue.vue')
	},
	{
		path: '/supplier-ledger',
		beforeEnter: guard,
		name: 'SupplierLedger',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "supplier-ledger" */ '../views/Supplier/SupplierLedger.vue')
	},
	/* purchase start */
	{
		path: '/purchase/:id?',
		beforeEnter: guard,
		name: 'PurchaseEntry',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase" */ '../views/Purchase/Purchase.vue')
	},
	{
		path: '/purchase-invoice/:id',
		beforeEnter: guard,
		name: 'PurchaseInvoice',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase-invoice" */ '../views/Purchase/PurchaseInvoice.vue')
	},
	{
		path: '/purchase-record',
		beforeEnter: guard,
		name: 'PurchaseRecord',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase-record" */ '../views/Purchase/PurchaseRecord.vue')
	},
	{
		path: '/purchase-report',
		beforeEnter: guard,
		name: 'PurchaseReport',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase-report" */ '../views/Purchase/PurchaseReport.vue')
	},
	{
		path: '/purchase-return/:id?',
		beforeEnter: guard,
		name: 'PurchaseReturn',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase-return" */ '../views/Purchase/PurchaseReturn.vue')
	},
	{
		path: '/purchase-return-invoice/:id',
		beforeEnter: guard,
		name: 'PurchaseReturnInvoice',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase-return-invoice" */ '../views/Purchase/PurchaseReturnInvoice.vue')
	},
	{
		path: '/purchase-return-record',
		beforeEnter: guard,
		name: 'PurchaseReturnRecord',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase-return-record" */ '../views/Purchase/PurchaseReturnRecord.vue')
	},
	/* purchase end */
	/* sale start */
	{
		path: '/sale/:id?',
		beforeEnter: guard,
		name: 'IssueEntry',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "purchase-record" */ '../views/Sale/Sale.vue')
	},
	{
		path: '/sale-invoice/:id',
		beforeEnter: guard,
		name: 'IssueInvoice',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "sale-invoice" */ '../views/Sale/SaleInvoice.vue')
	},
	{
		path: '/sale-record',
		beforeEnter: guard,
		name: 'IssueRecord',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "sale-record" */ '../views/Sale/SaleRecord.vue')
	},
	{
		path: '/sale-report',
		beforeEnter: guard,
		name: 'IssueReport',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "sale-report" */ '../views/Sale/SaleReport.vue')
	},
	{
		path: '/sale-return',
		beforeEnter: guard,
		name: 'IssueReturn',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "sale-return" */ '../views/Sale/SaleReturn.vue')
	},
	{
		path: '/sale-return-record',
		beforeEnter: guard,
		name: 'IssueReturnRecord',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "sale-return-record" */ '../views/Sale/SaleReturnRecord.vue')
	},
	{
		path: '/sale-return-invoice/:id',
		beforeEnter: guard,
		name: 'IssueReturnInvoide',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "sale-return-invoice" */ '../views/Sale/SaleReturnInvoice.vue')
	},
	{
		path: '/asset-code',
		beforeEnter: guard,
		name: 'AssetCode',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "asset-code" */ '../views/Sale/AssetCode.vue')
	},
	/* sale end */
	{
		path: '/stock',
		beforeEnter: guard,
		name: 'Stock',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "stock" */ '../views/Product/Stock.vue')
	},
	{
		path: '/inventory-monitor',
		beforeEnter: guard,
		name: 'InventoryMonitor',
		meta: { color: 'yellow' },
		component: () => import(/* webpackChunkName: "inventory-monitor" */ '../views/Purchase/InventoryMonitor.vue')
	},
	
	/* Inventory module end*/

	/* Exam module  start*/
	{
		path: '/exam-head',
		beforeEnter: guard,
		name: 'ExamHeadEntry',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "exam" */ '../views/Exam/ExamHead.vue')
	},
	{
		path: '/exam',
		beforeEnter: guard,
		name: 'ExamEntry',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "exam" */ '../views/Exam/Exam.vue')
	},
	{
		path: '/exams',
		beforeEnter: guard,
		name: 'ExamRecord',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "exams" */ '../views/Exam/ExamRecord.vue')
	},
	{
		path: '/exam-result/:id?',
		beforeEnter: guard,
		name: 'ExamResult',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "exam-result" */ '../views/Exam/Result.vue')
	},
	{
		path: '/result-record',
		beforeEnter: guard,
		name: 'ResultRecord',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "result-record" */ '../views/Exam/ResultRecord.vue')
	},
	{
		path: '/mark-sheet',
		beforeEnter: guard,
		name: 'MarkSheet',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "mark-sheet" */ '../views/Exam/MarkSheet.vue')
	},
	{
		path: '/result-publish',
		beforeEnter: guard,
		name: 'ResultPublish',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "result-publish" */ '../views/Exam/ResultPublish.vue')
	},
	{
		path: '/class-test/:id?',
		beforeEnter: guard,
		name: 'ClassTestEntry',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "class-test" */ '../views/Exam/ClassTest.vue')
	},
	{
		path: '/classtest-record',
		beforeEnter: guard,
		name: 'ClassTestRecord',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "classtest-record" */ '../views/Exam/ClasstestRecord.vue')
	},
	{
		path: '/classtest-print/:id',
		beforeEnter: guard,
		name: 'ClassTestInvoice',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "classtest-print" */ '../views/Exam/ClassTestInvoice.vue')
	},
	/* Exam module  end*/

	/* Transport module  end */
	{
		path: '/vehicle-entry',
		beforeEnter: guard,
		name: 'VehicleEntry',
		meta: { color: 'amber' },
		component: () => import(/* webpackChunkName: "vehicle-entry" */ '../views/Transport/VehicleEntry.vue')
	},
	{
		path: '/driver-entry',
		beforeEnter: guard,
		name: 'DriverEntry',
		meta: { color: 'amber' },
		component: () => import(/* webpackChunkName: "driver-entry" */ '../views/Transport/DriverEntry.vue')
	},
	{
		path: '/vehicle-record',
		beforeEnter: guard,
		name: 'VehicleRecord',
		meta: { color: 'amber' },
		component: () => import(/* webpackChunkName: "vehicle-record" */ '../views/Transport/VehicleRecord.vue')
	},
	{
		path: '/driver-record',
		beforeEnter: guard,
		name: 'DriverRecord',
		meta: { color: 'amber' },
		component: () => import(/* webpackChunkName: "driver-record" */ '../views/Transport/DriverRecord.vue')
	},
	{
		path: '/route',
		beforeEnter: guard,
		name: 'RouteEntry',
		meta: { color: 'amber' },
		component: () => import(/* webpackChunkName: "route" */ '../views/Transport/RouteEntry.vue')
	},
	{
		path: '/registration',
		beforeEnter: guard,
		name: 'Registration',
		meta: { color: 'amber' },
		component: () => import(/* webpackChunkName: "registration" */ '../views/Transport/Registration.vue')
	},
	{
		path: '/bus-card/:id',
		beforeEnter: guard,
		name: 'BusIdCard',
		meta: { color: 'amber' },
		component: () => import(/* webpackChunkName: "bus-card" */ '../views/Transport/Idcard.vue')
	},
	/* Transport module  end */

	/* Asset module  end */
	{
		path: '/asset-entry',
		beforeEnter: guard,
		name: 'AssetEntry',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "asset-entry" */ '../views/Asset/AssetEntry.vue')
	},
	{
		path: '/asset-sale',
		beforeEnter: guard,
		name: 'AssetSale',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "asset-sale" */ '../views/Asset/AssetSale.vue')
	},
	{
		path: '/asset-record',
		beforeEnter: guard,
		name: 'AssetRecord',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "asset-record" */ '../views/Asset/AssetRecord.vue')
	},
	{
		path: '/asset-sale-record',
		beforeEnter: guard,
		name: 'AssetSaleRecord',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "asset-sale-record" */ '../views/Asset/AssetSaleRecord.vue')
	},
	/* Asset module  end */
	{
		path: '/send-sms',
		beforeEnter: guard,
		name: 'SendSms',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "send-sms" */ '../views/Administration/SendSms.vue')
	},
	{
		path: '/notice',
		beforeEnter: guard,
		name: 'Notice',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "notice" */ '../views/Administration/Notice.vue')
	},
	{
		path: '/view-notice',
		beforeEnter: guard,
		name: 'ViewNotice',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "view-notice" */ '../views/Administration/ViewNotice.vue')
	},
	{
		path: '/holidays-weekend',
		beforeEnter: guard,
		name: 'HolidayWeekend',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "holidays-weekend" */ '../views/Administration/Holiday.vue')
	},
	{
		path: '/bank-type',
		beforeEnter: guard,
		name: 'BankType',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "bank-type" */ '../views/Administration/BankType.vue')
	},
	{
		path: '/attendance-list',
		beforeEnter: guard,
		name: 'AttendanceList',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "bank-type" */ '../views/Student/AttendanceList.vue')
	},
	{
		path: '/boards',
		beforeEnter: guard,
		name: 'Boards',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "boards" */ '../views/Administration/Board.vue')
	},
	{
		path: '/routine',
		beforeEnter: guard,
		name: 'RoutineEntry',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "routine" */ '../views/Administration/RoutineEntry.vue')
	},
	{
		path: '/class-routine',
		beforeEnter: guard,
		name: 'ClassRoutine',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "class-routine" */ '../views/Administration/ClassRoutine.vue')
	},
	{
		path: '/period',
		beforeEnter: guard,
		name: 'Period',
		meta: { color: 'green' },
		component: () => import(/* webpackChunkName: "period" */ '../views/Administration/Period.vue')
	},
	{
		path: '/admit-card',
		beforeEnter: guard,
		name: 'AdmitCard',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "admit-card" */ '../views/Exam/AdmitCard.vue')
	},
	{
		path: '/exam-routine',
		beforeEnter: guard,
		name: 'ExamRoutineEntry',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "exam-routine" */ '../views/Exam/ExamRoutineEntry.vue')
	},
	{
		path: '/exam-routines',
		beforeEnter: guard,
		name: 'ExamRoutine',
		meta: { color: 'orange' },
		component: () => import(/* webpackChunkName: "exam-routines" */ '../views/Exam/ExamRoutine.vue')
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
